
















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Reports } from '@/store';

@Component({})
export default class OverviewTab extends Vue {
    @Reports.Getter('commissioned_map') commissioned_map;

    @Prop() list;
    @Prop() total;

    PropUtils = PropUtils;

    getLocation(device){
        return Utils.deviceLocation(device);
    }
}
