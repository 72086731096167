






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import PropUtils from '@/modules/PropUtils';
import { Reports, Global, Props } from '@/store';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import API, { Types } from '@/modules/API';
import LoadingDialog from '@/components/LoadingDialog.vue';

@Component({ 
    components: {
        ConfirmDialog,
        LoadingDialog
    }
})
export default class AdvancedTab extends Vue {
    @Reports.Getter('commissioned_map') commissioned_map;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.Action('throwNotify') throwNotify;
    @Props.State('list') projectProperties;
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;

    @Prop() group;

    form_meta_device = [];
    searchField = {name: '', value: '', options: []};
    searchFields = [];
    searchValue = '';
    searchText = '';
    confirmUpdate = false;
    loadingUpdate = false;
    loading = true;

    mounted(){
        if (this.group.devices.length){
            this.setMetadataFieldsLists();
        }
        this.loading = false;
    }

    get searchValues(){
        this.searchValue = this.searchField.options[0];
        return this.searchField && this.searchField['options'] && this.searchField['options'].map((option) => this.$t(option));
    }

    get searchList(){
        return this.searchFields.map((field) => ({...field, name: this.$t(PropUtils.getFieldLowerToUpper(field.name)) }));
    }

    get totalDevices(){
        return this.commissionedFixtures.filter((device) => this.group.devices.includes(device.id)).length;
    }

    setMetadataFieldsLists(){
        if (this.commissionedFixtures.length) {
            this.form_meta_device = this.getMetadataProperty('dashboard.form_meta_device');
            if (this.form_meta_device.length){
                this.searchFields = this.form_meta_device.map((field) => {
                    const field_with_options = Object.keys(this.fixture_meta_device_options).length 
                        ? this.fixture_meta_device_options[field.name] || []
                        : [];
                    
                    return {
                        name: field.name, 
                        value: field.name, 
                        options: field_with_options
                    };
                });
                
                this.searchField = this.searchFields[0];
            }
        }
    }

    getMetadataProperty(property_name){
        const form_meta_device = PropUtils.getProperty(this.projectProperties, property_name);
        if (!form_meta_device) console.log(`There is no ${property_name} project property`);
        return form_meta_device && form_meta_device['value']['fields'] ? form_meta_device['value']['fields'] : [];
    }

    async setDevicesMetadata(){
        this.loadingUpdate = true;
        this.confirmUpdate = false;

        let success = true;
        const slices = Math.ceil(this.group.devices.length / 5);
        let startIndex = 0, endIndex = Math.min(this.group.devices.length, 5);
        try{
          for (let i = 0; i < slices; i++){
            const current = this.group.devices.slice(startIndex, endIndex);
            await Promise.all(current.map(async (device_id) => {
                let meta_device = this.commissioned_map.get(device_id)['meta.device'];
                try{
                    const result = await API.get(Types.DEVICES, `${device_id}/properties`, {name: 'meta.device'}, {});
                    if (result && result.results && result.results[0] && result.results[0].value && typeof result.results[0].value === 'string'){
                        try{
                            meta_device = JSON.parse(result.results[0].value);
                            PropUtils.reorderMetaDevice(meta_device);
                        }catch (e){}
                    } 
                }catch (e){}
                const uppercase_field = PropUtils.findMatchedUppercaseField(meta_device, this.searchField.value);
                meta_device[this.searchField.value] = this.searchValue;
                if (uppercase_field) {
                    meta_device[uppercase_field] = this.searchValue;
                }
                try{
                    const url = `/${device_id}/properties`;
                    await API.patch(Types.DEVICES, url, meta_device, {query: {name: 'meta.device'}});
                    this.commissioned_map.get(device_id)['meta.device'][this.searchField.value] = this.searchValue;
                    if (meta_device.hasOwnProperty(uppercase_field)) {
                        this.commissioned_map.get(device_id)['meta.device'][uppercase_field] = this.searchValue;
                    }
                }catch (e){
                    success = false;
                }
            }));          
            startIndex = endIndex;
            endIndex = Math.min(this.group.devices.length, endIndex + 5);
          }
        }catch (e){
            success = false;
        }
        this.loadingUpdate = false;
        this.showNotify(success);
    }

    showNotify(success){
        success 
        ?  this.throwNotify({
            type: 'success',
            title: `${this.$t('Success')}!`,
            text: this.$t(`Field has been updated in all devices`)
        })
        : this.throwNotify({
            type: 'error',
            title: `${this.$t('Error')}!`,
            text: this.$t(`Couldn't update field value`)
        });
    }
}
